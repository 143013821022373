
import { defineComponent, onMounted, ref } from "vue";
import Redacao from "@/interfaces/Redacao";
import BaseService from "@/services/admin/BaseService";
import { useRoute } from "vue-router";
import { requiredField } from "@/util/form-rules";
import useQuasar from 'quasar/src/composables/use-quasar.js';;

export default defineComponent({
  props: { id: { type: String, required: false } },
  setup() {
    const route = useRoute();
    const $q = useQuasar();
    const loading = ref<boolean>(false);
    const loadingButton = ref<boolean>(false);
    const url = ref<string>(process.env.VUE_APP_STORAGE_BASE);
    const redacao = ref<Redacao>();
    const showAnexo = ref<boolean>(false);
    const showModal = ref<boolean>(false);
    const token = ref<string>();
    const urlApi = ref<string>(process.env.VUE_APP_API_BASE);
    const respostaDigitada = ref<string>("");
    const respostaAnexada = ref();

    const openAnexo = (): void => {
      if (localStorage.getItem("token")) {
        token.value = "Bearer " + localStorage.getItem("token")?.toString();
        showAnexo.value = true;
      }
    };

    const openModal = (): void => {
      if (redacao?.value?.redacao_respondida) {
        respostaDigitada.value = redacao?.value?.redacao_respondida.resposta;
      }
      showModal.value = true;
    };

    const formatData = (data: string): string => {
      return `${data.substr(8, 2)}/${data.substr(5, 2)}/${data.substr(0, 4)}`;
    };

    const submeterRedacao = (id: string): void => {
      loadingButton.value = true;
      const formData = new FormData();
      formData.append("anexo_aluno", respostaAnexada.value ? respostaAnexada.value : "");
      formData.append("redacao_id", redacao.value?.id ? redacao.value.id : "");
      formData.append("resposta", respostaDigitada.value);
      BaseService.store(`aluno/minhas-redacoes/submeter-redacao`, formData)
        .then((res) => {
          showNotify({
            type: "positive",
            message: "Redação enviada com sucesso",
          });
          getRedacao();
          showModal.value = false;
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status == 422) {
            Object.entries(err.response.data.errors).forEach(([key, value]) => {
              const valueMessage: Array<string> = value as Array<string>;
              showNotify({
                type: "negative",
                message: valueMessage[0],
              });
            });
          } else {
            showNotify({
              type: "negative",
              message: "Erro ao enviar resposta",
            });
          }
        }).finally(() => {
          loadingButton.value = false;
        })
    };

    const onRejected = (rejectedEntries: any): void => {
      $q.notify({
        type: "negative",
        message: `${rejectedEntries.length} file(s) did not pass validation constraints`,
      });
    };

    const afterSubmit = (info: any): void => {
      showAnexo.value = false;
      showNotify({
        type: "positive",
        message: "Anexo enviado com sucesso",
      });
    };

    const afterFailed = (): void => {
      showNotify({
        type: "negative",
        message: "Erro ao enviar anexo",
      });
    };

    const showNotify = (opts: any): void => {
      $q.notify(opts);
    };

    const getRedacao = (): void => {
      redacao.value = undefined;
      loading.value = true;
      BaseService.list(`aluno/minhas-redacoes/${route.params.id}`)
        .then((res) => {
          redacao.value = res.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (loading.value = false));
    };

    const canAnswer = (): boolean => {
      const { value } = redacao;
      if (value?.data_inicio != undefined && value?.data_fim != undefined) {
        const data_inicio = new Date(value?.data_inicio.toLocaleString());
        const data_fim = new Date(value?.data_fim.toLocaleString());
        if (new Date().getTime() >= data_inicio.getTime() && new Date().getTime() <= data_fim.getTime() + 86400000) {
          return true;
        }
      }
      return false;
    }

    onMounted(() => {
      getRedacao();
    });

    return {
      loading,
      url,
      redacao,
      getRedacao,
      route,
      showAnexo,
      openAnexo,
      onRejected,
      afterSubmit,
      afterFailed,
      urlApi,
      token,
      showModal,
      openModal,
      respostaDigitada,
      submeterRedacao,
      respostaAnexada,
      formatData,
      loadingButton,
      canAnswer,
    };
  },
});
